<template>
  <div>
    <!-- 循环部分 -->
    <div class="course-list" v-loading="courseLoading">
      <div v-if="courseList.length > 0">
        <div
          v-for="(item, index) in courseList"
          :key="index"
          class="course-item"
        >
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <div class="header-title">
                  <div class="title">
                    <span> {{ item.title }}</span>
                  </div>
                  <div class="course-record" @click="seacherRecord(item)">
                    <span>观看记录</span>
                    <svg-icon
                      icon-class="jilu"
                      class="course-record"
                    ></svg-icon>
                  </div>
                </div>
                <ul class="header-right">
                  <li class="end-title">
                    <span>到期时间：</span>
                    <span>{{ parseTime(item.period) }}</span>
                  </li>
                </ul>
              </div>
            </template>
            <div class="list-grade">
              <div class="grade-con">
                <ul class="grade-item">
                  <li
                    class="item"
                    @click="toCoursePlayer(item, list)"
                    v-for="(list, lindex) in item.grade"
                    :key="lindex"
                  >
                    <div class="item-title">
                      <svg-icon
                        icon-class="shipin"
                        class="grade-icon-item"
                      ></svg-icon>
                      <span class="title">{{ list.title }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="grade-title" v-show="item.type == 1">
                <el-button
                  @click="getAgreeData(item)"
                  size="mini"
                  :disabled="agreementStyle"
                  :type="agreementStyle ? 'info' : 'danger'"
                >
                  <span>协议条款</span>
                  <svg-icon icon-class="layer" class="xieyi"></svg-icon>
                </el-button>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无课程数据"></el-empty>
      </div>
    </div>

    <!-- 观看进度模态框 -->
    <el-dialog :title="currentPackage.title" v-model="progressDialog" top="30px" width="80%">
      <div class="progress-grade" v-for="item in currentPackage.grade" :key="item.id" :name="item.id" @click="goHistoryPage(item)">
        <div class="grade-title">
          <svg-icon icon-class="shipin" class="grade-title-icon"></svg-icon>
          {{ item.title }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import { myCourse, checkAgree, getCourseHistory, } from "@/api/user"
import { parseTime } from "@/utils/index"
import { setLocalStorage, setSessionStorage } from '@/utils/auth'

export default {
  data() {
    return {
      courseList: [],
      packageData: [],
      gradeData: [],
      currentPackage: {},// 当前点开的观看记录
      progressDialog: false,
      courseLoading: true,
      agreementStyle: false,
    };
  },
  created() {
    // 套餐
    myCourse({ type: 1, status: 1 }).then((res) => {
      if (res.code == 1) {
        this.courseList = res.data;
        this.courseLoading = false;
        this.packageData = res.data;
        // 班级
        myCourse({ type: 2, status: 1 }).then((res) => {
          if (res.code == 1) {
            this.gradeData = res.data;
            this.courseList = this.packageData.concat(this.gradeData);
            this.courseLoading = false;
          }
        });
      }
    });
  },
  methods: {
    parseTime,
    toCoursePlayer(item, list) {
      // if (item.type == 1) {
      //   //套餐
      //   let comboid = item.product_id;
      //   let gradeid = list.id;
      //   //当前试听，章节id默认为0；
      //   this.$router.push({
      //     path: "/courseplayer/" + comboid + "/" + gradeid + "/0",
      //   });
      // } else {
      //   //班级
      //   let comboid = 0; //班级无套餐id,默认为0
      //   let gradeid = list.id;
      //   this.$router.push({
      //     path: "/courseplayer/" + comboid + "/" + gradeid + "/0",
      //   });
      // }

      // 2021-12-29 改
      let comboid = item.type == 1 ? item.product_id : 0
      let gradeid = list.id
      // 根据班级id查询观看记录，拿到最后一条记录
      getCourseHistory({grade_id: gradeid}).then(res => {
        let haveHistoryArr = res.data.list.filter(item => {return item.history})
        if (haveHistoryArr.length > 0) {
          setLocalStorage('sectionHistory', haveHistoryArr[haveHistoryArr.length - 1].history)
          let sectionId = haveHistoryArr[haveHistoryArr.length - 1].history.section_id
          this.$router.push('/courseplayer/' + comboid + '/' + gradeid + '/' + sectionId)
        } else {
          // 没有观看记录，就从第一节开始
          this.$router.push('/courseplayer/' + comboid + '/' + gradeid + '/0')
        }
      })
    },
    seacherRecord(data) {
      //观看进度
      this.progressDialog = true
      this.currentPackage = data
    },
    getAgreeData(data) {
      checkAgree({product_id: data.product_id, type: data.type, agreement_id: data.agreement_id}).then((res) => {
        if (res.code == 1) {
          if (res.data) {
            this.$notify({
              title: "已经签署",
              message: "协议已经签署，无需再签",
              type: "success",
              duration: 1500,
              showClose: false,
            });
            //this.agreementStyle = true;
          } else {
            this.$router.push("/agreement/" + data.type + "/" + data.product_id + "/" + data.agreement_id);
          }
        }
      });
    },
    goHistoryPage(grade) {
      console.log(this.currentPackage)
      setSessionStorage('gradeProgressCurrentGrade', {
        id: grade.id,
        title: grade.title,
        thumb: grade.thumb,
        comboId: this.currentPackage.type == 1 ? this.currentPackage.product_id : 0
      })
      // 当前班级各个章节进度，在另一个页面展示
      window.open('/user/progress/' + grade.id)
    },

  },
};
</script>

<style scoped lang="scss">
.box-card {
  margin-bottom: 10px;
}
.course-item {
  .card-header {
    height: 42px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: normal;
      font-size: 16px;
      .title {
        .typeclass {
          margin-right: 5px;
        }
      }
      .course-record {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
        font-size: 14px;
        .course-record {
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .course-record:hover {
        color: $theme-color;
      }
    }
    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      .end-title {
        font-size: 12px;
        color: #aaa;
      }
    }
  }
  .list-grade {
    display: flex;
    justify-content: space-between;
    .grade-title {
      margin-top: 10px;
      margin-right: 10px;
      .xieyi {
        font-size: 14px;
        margin-left: 5px;
      }
      .title-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .course-type {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 20px;
        }
      }
    }
    .grade-con {
      padding-left: 20px;
      .grade-item {
        .item {
          cursor: pointer;
          padding: 10px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .grade-icon-item {
          font-size: 18px;
          margin-right: 10px;
          color: #aaa;
        }
        .item:hover {
          color: $theme-color;
        }
        .item:hover .grade-icon-item {
          color: $theme-color;
        }
      }
      .more-item {
        cursor: pointer;
        height: 100%;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
        .more-order {
          font-size: 16px;
          margin-right: 3px;
        }
      }
      .more-item:hover {
        color: $theme-color;
      }
    }
  }

  .list-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #e8e8e8;
    .title {
      font-size: 16px;
    }
    .endtime {
      font-size: 12px;
      color: #666;
    }
  }
  .list-con {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px;
  }
  .list-con li {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #409eff;
  }
  .list-con li .item-icon {
    font-size: 28px;
    color: #409eff;
    margin-bottom: 5px;
  }
  .list-con li:hover {
    background-color: #409eff;
    color: #fff;
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  .list-con li:hover .item-icon {
    color: #fff;
  }
}

// 观看进度
.progress-grade {
  padding: 10px 0;

  .grade-title {

    .grade-title-icon {
      color: #aaa;
      font-size: 18px;
      margin-right: 10px;
    }
  }
  .grade-title:hover {
    color: $theme-color;
  }
  .grade-title:hover .grade-title-icon {
    color: $theme-color;
  }
}
</style>
